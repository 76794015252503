import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, Table, Button, Input, Space } from 'antd';
import type { TableProps, InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import { NumericFormat } from 'react-number-format';
import { jwtDecode } from "jwt-decode";
import Moment from 'react-moment';
import './styles.scss'

interface DataType {
  key: string;
  account_id: string;
  cus_name: string;
  symbol: string;
  volume: string;
  cost_price: string;
  value: string;
  m_value: string;
  weight: string;
  PL: string;
  pctPL: string;
  target_weight: string;
  extra_call: string;
  care_by: string;
  last_updated: string;
}

type DataIndex = keyof DataType;

interface ComponentProps {
  data: any | null
}

const getLatestUpdated = (data: DataType[]): string => {
  // Convert last_updated strings to Date objects and find the most recent one
  const latestItem = data?.reduce((latest, item) => {
    const itemDate = new Date(item?.last_updated);
    return itemDate > new Date(latest?.last_updated) ? item : latest;
  }, data[0]);

  return latestItem?.last_updated;
};

const CashFlow: React.FC<ComponentProps> = ({ data }) => {
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const decoded = jwtToken ? jwtDecode(jwtToken) : null;
  const user = decoded ? (decoded as { data: any })['data'] : null;
  const uniqueSymbols: string[] = [];
  const uniqueCarebys: string[] = [];

  data?.forEach((item: DataType) => {
    if (!uniqueSymbols.includes(item.symbol)) {
      uniqueSymbols.push(item.symbol);
    }

    if (!uniqueCarebys.includes(item.care_by)) {
      uniqueCarebys.push(item.care_by);
    }
  });

  const formattedSymbols = uniqueSymbols.map(symbol => ({
    text: symbol,
    value: symbol
  }));
  
  const formattedCareBys = uniqueCarebys.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: '#ffffff' }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#ffffff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'account_id',
      key: 'account_id',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('account_id'),
    },
    {
      title: 'Name',
      dataIndex: 'cus_name',
      key: 'cus_name',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('cus_name'),
    },
    {
      title: 'Ticker',
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'center',
      filters: formattedSymbols,
      filterSearch: true,
      onFilter: (value, record) => record.symbol.startsWith(value as string),
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      key: 'volume',
      align: 'right',
      sorter: (a, b) => parseFloat(a.volume) - parseFloat(b.volume),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'C-price',
      dataIndex: 'cost_price',
      key: 'cost_price',
      align: 'right',
      sorter: (a, b) => parseFloat(a.cost_price) - parseFloat(b.cost_price),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'right',
      sorter: (a, b) => parseFloat(a.value) - parseFloat(b.value),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'M-value',
      dataIndex: 'm_value',
      key: 'm_value',
      align: 'right',
      sorter: (a, b) => parseFloat(a.m_value) - parseFloat(b.m_value),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'W (%)',
      dataIndex: 'weight',
      key: 'weight',
      align: 'right',
      sorter: (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'P/L',
      dataIndex: 'PL',
      key: 'PL',
      align: 'right',
      sorter: (a, b) => parseFloat(a.PL) - parseFloat(b.PL),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'P/L (%)',
      dataIndex: 'pctPL',
      key: 'pctPL',
      align: 'right',
      sorter: (a, b) => parseFloat(a.pctPL) - parseFloat(b.pctPL),
      render: (text) => <CNumberToPercent number={text} color />,
    },
    {
      title: 'T-w',
      dataIndex: 'target_weight',
      key: 'target_weight',
      align: 'right',
      sorter: (a, b) => parseFloat(a.target_weight) - parseFloat(b.target_weight),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} suffix='%' thousandSeparator={true} />,
    },
    {
      title: 'Extra call',
      dataIndex: 'extra_call',
      key: 'extra_call',
      align: 'right',
      sorter: (a, b) => parseFloat(a.extra_call) - parseFloat(b.extra_call),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0) ?? 0} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Care by',
      dataIndex: 'care_by',
      key: 'care_by',
      align: 'center',
      hidden: user?.role_name !== 'admin',
      filters: formattedCareBys,
      filterSearch: true,
      onFilter: (value, record) => record.care_by.startsWith(value as string),
    }
  ];

  return (
    <>
      <Table
        className='c-ant-table'
        columns={columns}
        dataSource={data}
        size={'small'}
        tableLayout={'auto'}
        scroll={{ x: 'max-content' }}
        bordered
        title={() => (
          <Row>
            <Col span={12} className='left'>
              PORTFOLIO DETAILS
            </Col>
            <Col span={12} className='right'>
              Last updated: <Moment format={'DD/MM/YYYY'}>{getLatestUpdated(data)}</Moment>
            </Col>
          </Row>
        )} />
    </>
  );
};

export default CashFlow;
