import { useEffect, useState } from 'react';
import useProvideAuth from '../useProvideAuth';

export default function usePostTargetData(url, params) {
  const { token, isLogin } = useProvideAuth();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const postTargetData = async (url, account_id, params) => {

    if (url) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/v1/${url}/?account_id=${account_id}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });

        const responseData = await response.json();
        if (response.ok) {
          setData(responseData);
        } else {
          setError(responseData.detail);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      postTargetData(url, params);
    } 
  }, [isLogin, token, url, params]);

  return { data, error, mutate: postTargetData };
}