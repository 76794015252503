import { useState, useEffect } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function useStockData(params) {
  const { isLogin } = useProvideAuth();
  const [stockData, setStockData] = useState(null);

  const { data, error, mutate } = useFetchData('portfolio/stock-data', params);

  useEffect(() => {
    if (data && isLogin) {
      setStockData(data[0]);
    }
    if (error || params?.symbol === '') {
      setStockData(null);
    }
  }, [data, error]);

  return { stockData, mutate };
}