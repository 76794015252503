// CashFlow.tsx
import React from 'react';
import {
  Box,
  Text
} from '@chakra-ui/react';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import Moment from 'react-moment';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CLoading from '../../../../components/CLoading';
import './styles.scss';

interface ComponentProps {
  data: {
    details: any[];
    total_cash: string;
    total_required_revenue: string;
  } | null;
}

interface DataType {
  key: string;
  date: string;
  amount: string;
  mngt_time: string;
  hurdle_rate: string;
  required_revenue: string;
  expected_revenue: string;
  actual_revenue: string;
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Ngày nộp tiền',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: 80,
    render: (text) => <Moment format="DD/MM/YYYY">{text}</Moment>,
  },
  {
    title: 'Số tiền',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    width: 100,
    sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Thời gian quản lý',
    dataIndex: 'mngt_time',
    key: 'mngt_time',
    align: 'right',
    width: 100,
    sorter: (a, b) => parseFloat(a.mngt_time) - parseFloat(b.mngt_time),
  },
  {
    title: 'Hurdle rate',
    dataIndex: 'hurdle_rate',
    key: 'hurdle_rate',
    align: 'right',
    width: 80,
    render: (text) => <CNumberToPercent number={text} />,
  },
  {
    title: 'Lợi nhuận cơ sở',
    dataIndex: 'required_revenue',
    key: 'required_revenue',
    align: 'right',
    width: 100,
    sorter: (a, b) => parseFloat(a.required_revenue) - parseFloat(b.required_revenue),
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Diễn giải',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: 80,
  },
];

const CashFlow: React.FC<ComponentProps> = ({ data }) => {

  if (!data) return <CLoading />;

  const totalAmountAll = data?.details.reduce((sum, { amount }) => sum + parseFloat(amount), 0);
  const totalRevenueAll = data?.details.reduce((sum, { required_revenue }) => sum + parseFloat(required_revenue), 0);

  return (
    <Box>
      <Text fontSize={'2xl'} textAlign={'center'}>BẢNG ƯỚC TÍNH LỢI NHUẬN YÊU CẦU THEO DÒNG TIỀN</Text>
      <Table
        columns={columns}
        dataSource={data?.details}
        size={'small'}
        bordered
        tableLayout={'auto'}
        scroll={{ x: 'max-content' }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} align={'center'}>Tổng</Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <NumericFormat value={parseFloat(`${totalAmountAll}`).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4} align={'right'}>
                  <Text><NumericFormat value={parseFloat(`${totalRevenueAll}`).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} />
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Box>
  );
};

export default CashFlow;
