// CustomerList.tsx
import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Button, Input, Space } from 'antd';
import type { TableProps, InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import CLoading from '../../../../components/CLoading';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import { jwtDecode } from "jwt-decode";
import { useCustomerStats } from '../../../../hooks';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import './styles.scss'

interface DataType {
  key: string;
  account_id: string;
  name: string;
  capital: string;
  nav: string;
  ror: string;
  pct_cash: string;
  cash: string;
  cust_start_date: string;
  cust_end_date: string;
  account_type: string;
  mngt_time: string;
  revenue: string;
  hurdle_rate: string;
  perf_fee: string;
  care_by: string;
  last_updated: string;
}

type DataIndex = keyof DataType;

interface DataProps {
  scrollX: string;
}

const CustomerStats: React.FC<DataProps> = ({ scrollX = 'max-content' }) => {
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const decoded = jwtToken ? jwtDecode(jwtToken) : null;
  const user = decoded ? (decoded as { data: any })['data'] : null;
  const { customerStats } = useCustomerStats();
  const searchInput = useRef<InputRef>(null);

  if (!customerStats) return <CLoading />;

  const uniqueCarebys: string[] = (customerStats as DataType[]).reduce((acc: string[], item: DataType) => {
    if (!acc.includes(item.care_by)) {
      acc.push(item.care_by);
    }
    return acc;
  }, []);

  // Convert uniqueSymbols to the desired format
  const formattedCareBys = uniqueCarebys.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: '#ffffff' }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#ffffff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });
  
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'account_id',
      key: 'account_id',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('account_id'),
    },
    {
      title: 'Cust. name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Capital',
      dataIndex: 'capital',
      key: 'capital',
      align: 'right',
      sorter: (a, b) => parseFloat(a.capital) - parseFloat(b.capital),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'NAV',
      dataIndex: 'nav',
      key: 'nav',
      align: 'right',
      sorter: (a, b) => parseFloat(a.nav) - parseFloat(b.nav),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'RoR',
      dataIndex: 'ror',
      key: 'ror',
      align: 'right',
      sorter: (a, b) => parseFloat(a.ror) - parseFloat(b.ror),
      render: (text) => <CNumberToPercent number={text} color />,
    },
    {
      title: 'Cash (%)',
      dataIndex: 'pct_cash',
      key: 'pct_cash',
      align: 'right',
      sorter: (a, b) => parseFloat(a.pct_cash) - parseFloat(b.pct_cash),
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Cash',
      dataIndex: 'cash',
      key: 'cash',
      align: 'right',
      sorter: (a, b) => parseFloat(a.cash) - parseFloat(b.cash),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Start date',
      dataIndex: 'cust_start_date',
      key: 'cust_start_date',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.cust_start_date).unix() - moment(b.cust_start_date).unix(),
      render: (text) => <Moment format="DD/MM/YYYY">{text}</Moment>,
    },
    {
      title: 'End date',
      dataIndex: 'cust_end_date',
      key: 'cust_end_date',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.cust_end_date).unix() - moment(b.cust_end_date).unix(),
      render: (text) => <Moment format="DD/MM/YYYY">{text}</Moment>,
    },
    {
      title: 'Mgmt. time',
      dataIndex: 'mngt_time',
      key: 'mngt_time',
      align: 'center',
      sorter: (a, b) => parseFloat(a.mngt_time) - parseFloat(b.mngt_time),
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'right',
      sorter: (a, b) => parseFloat(a.revenue) - parseFloat(b.revenue),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Hurdle rate',
      dataIndex: 'hurdle_rate',
      key: 'hurdle_rate',
      align: 'right',
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Perf. fee',
      dataIndex: 'perf_fee',
      key: 'perf_fee',
      align: 'right',
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Care by',
      dataIndex: 'care_by',
      key: 'care_by',
      align: 'center',
      ellipsis: true,
      hidden: user?.role_name !== 'admin',
      filters: formattedCareBys,
      filterSearch: true,
      onFilter: (value, record) => record.care_by.startsWith(value as string),
    },
    {
      title: 'Account type',
      dataIndex: 'account_type',
      key: 'account_type',
      align: 'center',
      ellipsis: true,
      filterSearch: true,
      onFilter: (value, record) => record.account_type.startsWith(value as string),
    }
  ];

  const handleOnSubmit = (id: string) => {
    window.open(`/customer/${id}`, '_blank');
  };

  return (
    <>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: event => handleOnSubmit(record?.account_id), // click row
          };
        }}
        columns={columns}
        dataSource={customerStats}
        size={'small'}
        tableLayout={'auto'}
        scroll={{ x: scrollX}}
        bordered />
    </>
  );
};

export default CustomerStats;
