import React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  GridItem,
  Slider,
  SliderMark,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  Text
} from '@chakra-ui/react';
import { Col, Row, Empty } from 'antd';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CNumberToMillions from '../../../../components/CNumberToMillions';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import './styles.scss';

const labelTopStyles = {
  mt: '-8',
  ml: '-2.5',
  fontSize: 'sm',
};
const labelBottomStyles = {
  mt: '2',
  ml: '-5',
  fontSize: 'sm',
};

interface ComponentProps {
  data: any | null;
}

function calculatePercentage(custStartDateStr: string, custEndDateStr: string, curDateStr: string): number {
  const custStartDate = new Date(custStartDateStr);
  const custEndDate = new Date(custEndDateStr);
  const curDate = new Date(curDateStr);

  const totalMilliseconds = custEndDate.getTime() - custStartDate.getTime();
  const elapsedMilliseconds = curDate.getTime() - custStartDate.getTime();

  const percentage = (elapsedMilliseconds / totalMilliseconds) * 100;

  return parseFloat(percentage.toFixed(0));
}

function calculatePercentageNav(capital: string, curNav: string, maxNav: string): number {
  if (curNav < capital) return 0;
  
  const percentage = (parseFloat(curNav) - parseFloat(capital)) / (parseFloat(maxNav) - parseFloat(capital)) * 100;
  return parseFloat(percentage.toFixed(0));
}

const Overview: React.FC<ComponentProps> = ({ data }) => {

  if (!data) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  
  const percentage = calculatePercentage(data.cust_start_date, data.cust_end_date, data.cur_date);
  const percentageNav = calculatePercentageNav(data.capital, data.cur_nav, data.max_nav);

  return (
    <Row align="middle" justify="center">
      <Col xs={24} xl={14}>
        <GridItem paddingRight={4} paddingY={2} border={'1px'} borderColor={'brand.500'} borderRadius={'lg'} boxShadow={'md'}>
          <Box paddingX={3} paddingY={4}>
            <Flex minWidth={'max-content'} alignItems={'center'} gap={2}>
              <Text flex={20} minWidth={95}>Thời gian quản lý</Text>
              <Slider flex={80} defaultValue={Math.min(percentage, 100)} isReadOnly>
                <SliderMark value={0} {...labelTopStyles}>
                  <Moment format="DD/MM">{data.cust_start_date}</Moment>
                </SliderMark>
                <SliderMark value={100} {...labelTopStyles}>
                  <Moment format="DD/MM">{data.cust_end_date}</Moment>
                </SliderMark>
                <SliderMark
                  value={Math.min(percentage, 100)}
                  textAlign={'center'}
                  mt={'3'}
                  ml={'-3'}
                >
                  {percentage}%
                </SliderMark>
                <SliderTrack bg={'brand.500'} height={'15px'} borderRadius={'5px'}>
                  <SliderFilledTrack bg={'#D9D9D9'} />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Flex>
          </Box>
          <Box paddingX={3} paddingY={4}>
            <Flex minWidth={'max-content'} alignItems={'center'} gap={2}>
              <Text flex={20} minWidth={'95px'}>Vốn đầu tư</Text>
              <Slider flex={80} defaultValue={Math.min(percentageNav, 100)} isReadOnly>
                <SliderMark value={0} {...labelBottomStyles}>
                  <CNumberToMillions number={data.capital} />
                </SliderMark>
                <SliderMark value={100} {...labelBottomStyles}>
                  <CNumberToMillions number={data.max_nav} />
                </SliderMark>
                <SliderMark
                  value={Math.min(percentageNav, 100)}
                  textAlign={'center'}
                  mt={'-8'}
                  ml={'-3'}
                >
                  <CNumberToMillions number={data.cur_nav} />
                </SliderMark>
                <SliderTrack bg={'#D9D9D9'} height={'15px'} borderRadius={'5px'}>
                  <SliderFilledTrack bg={'#1AC917'} />
                </SliderTrack>
                <SliderThumb className='c-ant-slider-thumb-top' />
              </Slider>
            </Flex>
          </Box>
        </GridItem>
      </Col>
      <Col xs={24} xl={10}>
        <GridItem colStart={{ base: 1, md: 4 }} colEnd={{ base: 1, md: 6 }} paddingX={2} paddingY={2}>
          <Flex height={'100%'} alignItems={'center'} justify={'space-between'}>
            <Box textAlign={'center'}>
              <CircularProgress value={100} color={'brand.500'} thickness={'1px'}>
                <CircularProgressLabel fontSize={'lg'}>
                  <NumericFormat value={parseFloat(data.benchmark).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </CircularProgressLabel>
              </CircularProgress>
              <Text>Benchmark</Text>
            </Box>
            <Box className={'chakra-progress-gray'} textAlign={'center'}>
              <CircularProgress value={100} color={'brand.500'} thickness={'1px'}>
                <CircularProgressLabel fontSize={'lg'}>
                  <CNumberToPercent number={data.hurdle_rate} />
                </CircularProgressLabel>
              </CircularProgress>
              <Text>Hurdle rate</Text>
            </Box>
            <Box className={'chakra-progress-gray'} textAlign={'center'}>
              <CircularProgress value={100} color={'brand.500'} thickness={'1px'} sx={{ fill: 'brand.500' }}>
                <CircularProgressLabel fontSize={'lg'}>
                  <CNumberToPercent number={data.perf_fee_rate} />
                </CircularProgressLabel>
              </CircularProgress>
              <Text>Perf. fee rate</Text>
            </Box>
          </Flex>
        </GridItem>
      </Col>
    </Row>
  );
};

export default Overview;
