// Layout.tsx
import React from 'react';
import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react';
import Header from './_Header';
import Footer from './_Footer';
import { useParams } from "react-router-dom";
import { useCustomer } from '../hooks';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: 'gray.100',
      },
    },
  },
  colors: {
    brand: {
      100: "#BFD0EA",
      200: "#90B3E5",
      300: "#6F98D2",
      400: "#3A65A3",
      500: "#1F4172",
    },
    orange: {
      100: "#EC9E56",
    }
  },
});

interface LayoutProps {
  children: React.ReactNode;
}

interface RouteParams {
  customerId: string;
}

const Customer: React.FC<LayoutProps> = ({ children }) => {
  const { customerId }: RouteParams = useParams<RouteParams>();
  const { customer } = useCustomer({ account_id: customerId });

  return (
    <ChakraProvider theme={theme}>
      <Box>
        {/* Header */}
        <Header customer={customer ? customer : null} />

        {/* Main Content */}
        <Box mx="auto" pt={8} pb={16}>
          {children}
        </Box>

        {/* Footer */}
        <Footer />
      </Box>
    </ChakraProvider>
  );
};

export default Customer;
