// Transaction.tsx
import React from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CLoading from '../../../../components/CLoading';
import './styles.scss'

type DataType = {
  key: string;
  asset: string;
  asset_type: string;
  c_value: number | null;
  c_price: number | null;
  m_value: number | null;
  m_price: number | null;
  pl: number | null;
  pct_pl: number | null;
  symbol?: string;
  volume?: number | null;
  weight?: number | null;
  t_weight?: number | null;
  t_volume?: number | null;
  extra_call?: number | null;
  extra_call_value?: number | null;
  children?: DataType[];
};

const columns: TableColumnsType<DataType> = [
  {
    title: '',
    dataIndex: 'symbol',
    key: 'symbol',
    fixed: 'left',
  },
  {
    title: 'Khối lượng',
    dataIndex: 'volume',
    key: 'volume',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá vốn',
    dataIndex: 'c_price',
    key: 'c_price',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá thị trường',
    dataIndex: 'm_price',
    key: 'm_price',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá trị mua',
    dataIndex: 'c_value',
    key: 'c_value',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá trị thị trường',
    dataIndex: 'm_value',
    key: 'm_value',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Tỷ trọng',
    dataIndex: 'weight',
    key: 'weight',
    align: 'right',
    render: (text) => <CNumberToPercent number={text} />,
  },
  {
    title: 'Lãi/lỗ',
    dataIndex: 'pl',
    key: 'pl',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Lãi/lỗ (%)',
    dataIndex: 'pct_pl',
    key: 'pct_pl',
    align: 'right',
    render: (text) => text && <CNumberToPercent number={text} color={true} />,
  },
  {
    title: 'Tỷ trọng mục tiêu',
    dataIndex: 't_weight',
    key: 't_weight',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} suffix='%' />,
  },
  {
    title: 'Số lượng mục tiêu',
    dataIndex: 't_volume',
    key: 't_volume',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Mua / bán',
    dataIndex: 'extra_call',
    key: 'extra_call',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá trị mua / bán',
    dataIndex: 'extra_call_value',
    key: 'extra_call_value',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  }
];

function groupAssets(assets: DataType[]): DataType[] {
  const grouped: { [key: string]: DataType } = {};
  let ckTotalWeight = 0;

  // First pass: Process 'CK' group and calculate ckTotalWeight
  assets?.forEach((asset, index) => {
    if (asset?.asset_type === 'CK') {
      if (!grouped['CK']) {
        grouped['CK'] = {
          key: `group-${index}`,
          asset: asset.asset,
          asset_type: asset.asset_type,
          symbol: asset.asset,
          c_value: 0,
          c_price: 0,
          m_value: 0,
          m_price: 0,
          pl: 0,
          pct_pl: 0,
          volume: null,
          weight: 0,
          t_weight: 0,
          t_volume: null,
          extra_call: null,
          extra_call_value: null,
          children: []
        };
      }

      const group = grouped['CK'];
      group.children!.push({ ...asset, key: `child-${index}` });

      // Accumulate values for the 'CK' group
      group.c_value! += asset.c_value || 0;
      group.c_price! += asset.c_price || 0;
      group.m_value! += asset.m_value || 0;
      group.m_price! += asset.m_price || 0;
      group.pl! += asset.pl || 0;
      group.weight! += asset.weight || 0;
      group.t_weight! += asset.t_weight || 0;
      ckTotalWeight += asset.t_weight || 0;
    }
  });

  // Second pass: Process non-'CK' assets and adjust their `t_weight`
  assets?.forEach((asset, index) => {
    if (asset?.asset_type !== 'CK') {
      grouped[asset.asset_type] = {
        ...asset,
        symbol: asset.asset,
        c_value: null,
        c_price: null,
        m_value: asset.c_value,
        m_price: null,
        pl: null,
        pct_pl: null,
        volume: null,
        t_weight: 100 - ckTotalWeight, // Adjusted t_weight for non-'CK'
        t_volume: null,
        extra_call: null,
        extra_call_value: null,
        key: `asset-${index}`
      };
    }
  });

  // Final pass: Convert grouped objects to array and adjust formatting
  return Object.values(grouped).map(group => {
    if (group.children?.length) {
      const pct_pl = group.pl && group.c_value ? group.pl / group.c_value : 0;
      return {
        ...group,
        c_value: group.c_value || null,
        m_value: group.m_value || null,
        weight: group.weight || null,
        t_weight: group.t_weight || null,
        pl: group.pl || null,
        pct_pl: pct_pl || null,
      };
    }
    return group;
  });
}

interface ComponentProps {
  assetStatus: any | null
}

const Transaction: React.FC<ComponentProps> = ({ assetStatus }) => {

  if (!assetStatus) return <CLoading />;
  
  const data: DataType[] = groupAssets(assetStatus);

  return (
    <>
      <Table
        defaultExpandAllRows
        className={'c-ant-table-expend'}
        columns={columns}
        dataSource={data}
        size={'small'}
        tableLayout={'auto'}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={false}
      />
    </>
  );
};

export default Transaction;
