import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, Table, Button, Input, Space } from 'antd';
import type { TableProps, InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import './styles.scss'

interface DataType {
  key: string;
  account_id: string;
  amount: string;
  description: string;
  name: string;
  date: Date;
  last_updated: string;
}

type DataIndex = keyof DataType;

interface ComponentProps {
  data: any | null
}

const getLatestUpdated = (data: DataType[]): string => {
  // Convert last_updated strings to Date objects and find the most recent one
  const latestItem = data?.reduce((latest, item) => {
    const itemDate = new Date(item?.last_updated);
    return itemDate > new Date(latest?.last_updated) ? item : latest;
  }, data[0]);

  return latestItem?.last_updated;
};

const CashFlow: React.FC<ComponentProps> = ({ data }) => {

  const searchInput = useRef<InputRef>(null);
  const [currentDataSource, setCurrentDataSource] = useState(data);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: '#ffffff' }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#ffffff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'account_id',
      key: 'account_id',
      align: 'center',
      ...getColumnSearchProps('account_id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (text) => <Moment format='DD/MM/YYYY'>{text}</Moment>,
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix()
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setCurrentDataSource(extra?.currentDataSource);
  };

  return (
    <>
      <Table
        className='c-ant-table'
        columns={columns}
        dataSource={data}
        size={'small'}
        tableLayout={'auto'}
        scroll={{ x: 'max-content' }}
        bordered
        onChange={onChange}
        summary={(pageData) => {
          let totalAmount = 0;
          pageData = currentDataSource
          
          pageData?.forEach(({ amount }) => {
            totalAmount += parseFloat(amount);
          });

          return (
            <>
              <Table.Summary.Row className='c-ant-table-summary'>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} align={'right'}>
                  <NumericFormat value={parseFloat(`${totalAmount}`).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} />
              </Table.Summary.Row>
            </>
          );
        }}
        title={() => (
          <Row>
            <Col span={12} className='left'>
              CASHFLOW
            </Col>
            <Col span={12} className='right'>
              Last updated: <Moment format={'DD/MM/YYYY'}>{getLatestUpdated(data)}</Moment>
            </Col>
          </Row>
        )}
      />
    </>
  );
};

export default CashFlow;
