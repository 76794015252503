// PortfolioStats.tsx
import React from 'react';
import { Grid, GridItem, Box, Heading, Text } from '@chakra-ui/react';
import { Empty } from 'antd';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CNumberToMillions from '../../../../components/CNumberToMillions';

interface ComponentProps {
  data: any | null;
}

const PortfolioStats: React.FC<ComponentProps> = ({ data }) => {

  if (!data) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  
  return (
    <Grid templateColumns={'repeat(5, 1fr)'} overflowX={'auto'} boxShadow={'md'}>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={data?.nav} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>NAV</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={data?.capital} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Vốn ban đầu</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={data?.revenue} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Lợi nhuận</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToPercent number={data?.avg_ror} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Tỷ suất sinh lời</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={data?.perf_fee} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Phí hiệu quả</Text>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default PortfolioStats;
