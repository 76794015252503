import React from 'react';
import { Col, Row } from 'antd';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../CNumberToPercent';
import './styles.scss';

interface TooltipProps {
  data: any;
}

const CChartTooltip: React.FC<TooltipProps> = ({ data }) => {

  return (
    <div className="MyChartTooltip">
      <Row style={{ alignItems: 'top' }}>
        <Col span={12} style={{ textAlign: 'right' }}>Giá vốn: <NumericFormat value={data?.avg_cost_price} displayType={'text'} thousandSeparator={true} /></Col>
        <Col span={12} style={{ textAlign: 'right' }}>Giá mục tiêu: <NumericFormat value={data?.t_price} displayType={'text'} thousandSeparator={true} /></Col>
      </Row>
      <Row style={{ paddingTop: '5px', alignItems: 'top' }}>
        <Col span={12} style={{ textAlign: 'right' }}>Giá thị trường: <NumericFormat value={data?.m_price} displayType={'text'} thousandSeparator={true} /></Col>
        <Col span={12} style={{ textAlign: 'right', textTransform: 'capitalize' }}>{data?.trend}: <CNumberToPercent number={data?.pct_trend_value} color /></Col>
      </Row>
      <Row style={{ paddingTop: '15px', alignItems: 'top', textAlign: 'center' }}>
        <Col span={24}>
          Tỷ trọng mục tiêu: <NumericFormat value={data?.t_weight ?? 0} displayType={'text'} thousandSeparator={true} suffix='%' />
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px', alignItems: 'top', textAlign: 'center' }}>
        <Col span={24}>
          KL mục tiêu: <NumericFormat value={data?.t_volume ?? 0} displayType={'text'} thousandSeparator={true} />
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px', alignItems: 'top', textAlign: 'center' }}>
        <Col span={24}>
          KL cần mua thêm/bán ra: {data?.call > 0 ? (
            <NumericFormat value={data?.call} displayType={'text'} thousandSeparator={true} prefix='+' style={{ color: 'green' }} />
          ) : (
            <NumericFormat value={data?.short} displayType={'text'} thousandSeparator={true} prefix='-' style={{ color: 'red' }} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CChartTooltip;
