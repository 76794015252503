// DashboardFeature.tsx
import React, { useState } from 'react';
import Layout from '../../layouts/Main';
import Summary from './components/Summary';
import CustomerStats from './components/CustomerStats';
import AssetAllocation from './components/AssetAllocation';
import StockPorfolio from './components/StockPorfolio';
import Customer from './components/Customer';
import WatchList from './components/WatchList';
import CashFlow from './components/CashFlow';
import PortfolioDetails from './components/PortfolioDetails';
import Import from './components/Import';
import { Col, Row, Drawer, FloatButton } from 'antd';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, Container } from '@chakra-ui/react';
import { useCustomer, useWatchList, useCashFlow, usePortfolioDetails } from '../../hooks';

const DashboardFeature: React.FC = () => {

  const { watchList } = useWatchList();
  const { cashFlow } = useCashFlow();
  const { portfolioDetails } = usePortfolioDetails();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Layout>
      <Container maxW={'container.6xl'}>
        <Row>
          <Col span={24}>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <Summary />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <CustomerStats scrollX={''} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <AssetAllocation />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <StockPorfolio />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <PortfolioDetails data={portfolioDetails} />
            </Box>
          </Col>
        </Row>
        <FloatButton.Group
          type="primary"
          trigger="click"
          style={{ insetInlineEnd: 15, bottom: 60 }}
          icon={<PlusOutlined />}
        >
          <FloatButton onClick={showDrawer} tooltip={<div>More</div>} />
          <FloatButton onClick={showModal} icon={<ImportOutlined />} tooltip={<div>Import</div>} />
        </FloatButton.Group>

        <Drawer onClose={onClose} open={open} width={'90vw'} title={'Danh sách customer, watchlist and cashflow'}>
          <Box mb={5}>
            <Customer />
          </Box>
          <Box mb={5}>
            <WatchList data={watchList} />
          </Box>
          <Box mb={5}>
            <CashFlow data={cashFlow} />
          </Box>
        </Drawer>
        <Import openModal={openModal} onCloseModal={onCloseModal} />
      </Container>
    </Layout >
  );
};

export default DashboardFeature;
